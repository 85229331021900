.ps {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  &_container {
    width: 40%;
    &_modal {
      svg {
        width: 100px;
        display: block;
        margin: 40px auto 0;
      }
      .path {
        stroke-dasharray: 1000;
        stroke-dashoffset: 0;
        &.circle {
          -webkit-animation: dash 0.9s ease-in-out;
          animation: dash 0.9s ease-in-out;
        }
        &.line {
          stroke-dashoffset: 1000;
          -webkit-animation: dash 0.9s 0.35s ease-in-out forwards;
          animation: dash 0.9s 0.35s ease-in-out forwards;
        }
        &.check {
          stroke-dashoffset: -100;
          -webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
          animation: dash-check 0.9s 0.35s ease-in-out forwards;
        }
      }

      p {
        text-align: center;
        margin: 20px 0 30px;
        font-size: 1.25em;
        &.success {
          color: #73af55;
        }
        &.error {
          color: #d06079;
        }
      }

      @-webkit-keyframes dash {
        0% {
          stroke-dashoffset: 1000;
        }
        100% {
          stroke-dashoffset: 0;
        }
      }

      @keyframes dash {
        0% {
          stroke-dashoffset: 1000;
        }
        100% {
          stroke-dashoffset: 0;
        }
      }

      @-webkit-keyframes dash-check {
        0% {
          stroke-dashoffset: -100;
        }
        100% {
          stroke-dashoffset: 900;
        }
      }

      @keyframes dash-check {
        0% {
          stroke-dashoffset: -100;
        }
        100% {
          stroke-dashoffset: 900;
        }
      }
    }
    &_button {
      width: 100%;
      display: flex;
      justify-content: center;
      button {
        width: fit-content;
        padding: 10px 20px;
        border-radius: 10px;
        border: none;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.2px;
        text-align: center;
        cursor: pointer;
        color: #ffffff;
        background: #092b46;
        transition: transform 0.1s ease;
      }
    }
  }
  .loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
  }
}
