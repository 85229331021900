.doc {
    &_cont {
      width: 100%;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      padding: 30px 0;
    }
    &_sec {
      width: 90%;
      display: flex;
      align-items: center;
      flex-direction: column;
      &_header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        padding-bottom: 5px;
        border-bottom: 1px solid #c3c3c3;
        &_back {
          display: flex;
          align-items: center;
          cursor: pointer;
        }
        h3 {
          font-size: 24px;
          font-weight: 600;
        }
        button {
          padding: 10px 20px;
          color: #ffffff;
          background: #104581;
          border-radius: 5px;
          border: none;
          font-size: 16px;
          font-weight: 600;
          transition: transform 0.1s ease;
          cursor: pointer;
        }
      }
      &_table {
        width: 100%;
        margin-bottom: 50px;
        table {
          border-collapse: collapse;
          width: 100%;
        }
        td,
        th {
          border: 1px solid #dddddd;
          text-align: left;
          padding: 8px;
          min-width: 100px;
          line-break: anywhere;
        }
        .trash {
          color: red;
          font-size: 30px;
          cursor: pointer;
          padding: 5px;
        }
        // .trash:hover {
        //   border: 1px solid #ddd;
        //   border-radius: 50px;
        // }
        .apikeyrow {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 10px;
        }
      }
      &_container {
        width: 100%;
        &_document {
          &_title {
            margin-bottom: 20px;
            h3 {
              font-size: 24px;
              font-weight: 600;
              margin-bottom: 5px;
            }
            p {
              margin-left: 25px;
              font-size: 16px;
              font-weight: 400;
            }
          }
          &_points {
            margin-bottom: 15px;
            h4 {
              font-size: 20px;
              font-weight: 600;
              margin-bottom: 5px;
            }
            p {
              margin-left: 25px;
              font-size: 16px;
              font-weight: 400;
            }
          }
        }
        &_content {
          width: 100%;
          display: flex;
          flex-direction: column;
          margin-bottom: 20px;
          h2 {
            font-size: 24px;
            font-weight: 600;
            margin-bottom: 5px;
          }
          p {
            margin-left: 40px;
            font-size: 16px;
            font-weight: 400;
          }
          &:nth-child(2) {
            margin-top: 50px;
          }
          &_left {
            width: 100%;
            h3 {
              font-size: 24px;
              font-weight: 600;
              margin-bottom: 5px;
            }
            h4 {
              font-size: 16px;
              font-weight: 600;
            }
            ul {
              li {
                padding: 5px;
                margin-left: 40px;
                font-size: 16px;
                font-weight: 400;
                list-style-type: disc;
              }
            }
          }
          &_right {
            width: 100%;
            &_header {
              width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: center;
              background-color: #6b6b6b;
              color: #fff;
              padding: 5px 10px;
              border-bottom: 1px solid #fff;
              border-radius: 5px 5px 0 0;
              &_left {
                h3 {
                  font-size: 16px;
                  font-weight: 600;
                  color: #fff;
                }
              }
              &_right {
                .curlicon {
                  color: #fff;
                }
              }
            }
            &_curl {
              padding: 10px 20px;
              width: 100%;
              background-color: #000;
              color: #fff;
              overflow-x: auto;
              border-radius: 0 0 5px 5px;
              pre {
                width: 100%;
                code {
                  width: 100%;
                  font-size: 14px;
                }
              }
            }
          }
          &_explanation {
            padding-left: 20px;
            &_heading {
              margin: 10px 0;
              h3 {
                font-size: 20px;
                font-weight: 600;
                margin-bottom: 5px;
              }
            }
            &_details {
              &_points {
                margin-bottom: 10px;
                h2 {
                  font-size: 16px;
                  font-weight: 600;
                  margin-bottom: 5px;
                }
                .code1 {
                  font-size: 16px;
                  padding-left: 20px;
                }
                li {
                  padding-left: 20px;
                  font-size: 16px;
                  code {
                    font-size: 16px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
  }
  .confirm {
    position: fixed;
    background-color: #00000021;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    &_popup {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #fff;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      width: 450px;
      &_inner {
        display: flex;
        flex-direction: column;
        h2 {
          font-size: 24px;
          color: #6b6a6a;
          font-weight: 600;
          margin-bottom: 10px;
          text-align: center;
        }
        label {
          display: block;
          margin-bottom: 10px;
          font-size: 18px;
          font-weight: 500;
          color: #6b6a6a;
        }
        input {
          width: 100%;
          padding: 8px;
          margin-bottom: 20px;
          border: 1px solid #ddd;
          border-radius: 5px;
          color: #5c5b5b;
          font-size: 18px;
        }
      }
      &_buttons {
        display: flex;
        justify-content: flex-end;
        gap: 20px;
        button {
          padding: 8px 20px;
          color: #fff;
          background: #104581;
          border-radius: 5px;
          border: none;
          font-size: 16px;
          font-weight: 600;
          cursor: pointer;
        }
      }
    }
  }
  
  @media screen and (min-width: 320px) and (max-width: 767px) {
    .api {
      &_cont {
        padding: 60px 5px;
      }
      &_sec {
        width: 95%;
        &_table {
          overflow-x: scroll;
        }
      }
    }
    .confirm {
      &_popup {
        width: 300px;
        &_buttons {
          justify-content: space-between;
        }
      }
    }
  }
  