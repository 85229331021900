.price {
  width: 100%;
  &_cont {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    place-items: center;
    padding: 20px 0;
    &_card {
      width: 100%;
      max-width: 300px;
      .plan {
        border-radius: 16px;
        box-shadow: 0 30px 30px -25px rgba(0, 38, 255, 0.205);
        padding: 10px;
        background-color: hsl(192, 100%, 80%, 0.4);
        color: #697e91;
      }
      .plan strong {
        font-weight: 600;
        color: #425275;
      }
      .plan .inner {
        align-items: center;
        padding: 20px;
        padding-top: 40px;
        background-color: #ecf0ff;
        border-radius: 12px;
        position: relative;
        // height: 380px;
      }
      .plan .pricing {
        position: absolute;
        top: 0;
        right: 0;
        background-color: #bed6fb;
        border-radius: 200px 0 0 200px;
        display: flex;
        align-items: center;
        padding: 8px 8px;
        font-size: 22px;
        font-weight: 600;
        color: #425475;
      }
      .plan .pricing small {
        color: #707a91;
        font-size: 16px;
        margin-left: 2px;
      }
      .plan .title {
        font-weight: 600;
        font-size: 22px;
        margin-top: 10px;
        color: #425675;
        text-align: center;
      }
      .plan .title + * {
        text-align: center;
      }
      // .plan .info + * {
        // margin-top: 14px;
      // }
      .plan .info2 {
        font-size: 16px;
        color: #425675;
        font-weight: 600;
        text-align: center;
      }
      .plan .features {
        display: flex;
        flex-direction: column;
      }
      .plan .features li {
        display: flex;
        align-items: center;
        gap: 10px;
      }
      .plan .features li + * {
        margin-top: 10px;
      }
      .plan .features .icon {
        background-color: #1fcac5;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-radius: 50%;
        // width: 25px;
        // height: 20px;
      }
      .plan .features .icon svg {
        width: 14px;
        height: 14px;
      }
      .plan .features + * {
        margin-top: 22px;
      }
      .plan .action {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
.details {
  position: fixed;
  background-color: #00000021;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  &_popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 450px;
    &_header {
      display: flex;
      flex-direction: column;
      h2 {
        font-weight: 600;
        font-size: 20px;
        color: #425675;
        text-align: center;
      }
    }
    &_body {
      &_top {
        margin-bottom: 10px;
        padding-bottom: 4px;
        border-bottom: 1px solid #d5d5d5;
        h2 {
          font-size: 16px;
          color: #6a6b6a;
          font-weight: 600;
          text-transform: capitalize;
        }
        span {
          color: #707a91;
          font-size: 12px;
        }
      }
      &_center {
        display: flex;
        flex-direction: column;
        span {
          color: #707a91;
          font-size: 14px;
          padding-bottom: 6px;
        }
      }
    }
    &_buttom {
      display: flex;
      justify-content: center;
      gap: 20px;
      button {
        padding: 8px 20px;
        color: #fff;
        background: #104581;
        border-radius: 5px;
        border: none;
        font-size: 16px;
        font-weight: 500;
        margin: 0 20px;
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .price {
    &_cont {
      &_card {
        width: 90%;
      }
    }
  }
}
