.edit-profile-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
  
    .modal-content {
      background: #fff;
      padding: 20px;
      border-radius: 8px;
      width: 300px;
      display: flex;
      flex-direction: column;
      gap: 10px;
  
      h2 {
        margin: 0 0 10px;
      }
  
      label {
        font-weight: 500;
      }
  
      input[type="text"] {
        padding: 8px;
        border: 1px solid #ddd;
        border-radius: 4px;
      }
  
      input[type="file"] {
        border: none;
      }
  
      .modal-buttons {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
      }
    }
  }
  