.bd {
  position: fixed;
  background-color: #00000021;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  &_popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 350px;
    &_inner {
      display: flex;
      flex-direction: column;
      h2 {
        font-size: 22px;
        color: #6b6a6a;
        font-weight: 600;
        margin-bottom: 20px;
        text-align: center;
      }
      input {
        width: 100%;
        padding: 5px;

        border: 1px solid #ddd;
        border-radius: 5px;
        color: #5c5b5b;
        font-size: 18px;
      }
    }
    &_buttons {
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }
  }
}

.custom {
  position: fixed;
  background-color: #00000021;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  &_popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 400px;
    &_inner {
      display: flex;
      flex-direction: column;
      h2 {
        font-size: 22px;
        color: #6b6a6a;
        font-weight: 600;
        margin-bottom: 20px;
        text-align: center;
      }
      label {
        display: block;
        margin-bottom: 5px;
        font-size: 12px;
        font-weight: 500;
        color: #6b6a6a;
      }
      input {
        width: 100%;
        padding: 5px;
        border: 1px solid #ddd;
        border-radius: 5px;
        color: #5c5b5b;
        font-size: 18px;
      }
    }
    &_buttons {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
    }
  }
}
