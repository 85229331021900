.cp {
    &_cont {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &_sec {
      width: 40%;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 25px;
      &_header {
        width: 100%;
        h2 {
          font-size: 24px;
          font-weight: 700;
          text-align: center;
          color: #838383;
        }
      }
      &_inputs {
        width: 100%;
      }
      &_file {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        border-radius: 10px;
        padding: 20px 0;
        margin-bottom: 10px;
        box-shadow: 0px 0px 4px 1px #dddddd;
        &_choose {
          width: 85%;
          height: 100px;
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          padding: 18px;
          border: 2px dashed #c3c3c3;
          img {
            width: 40px;
            height: 40px;
            cursor: pointer;
          }
          h3 {
            padding-top: 14px;
            font-size: 16px;
            color: #838383;
            font-weight: 600;
          }
        }
      }
      &_button {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }
    }
  }