.db {
    width: 100%;
    &_sec {
      padding: 20px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
      &_card {
        padding: 20px;
        border: 1px solid #c3c3c3;
        border-radius: 10px;
        cursor: pointer;
        &_top {
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px;
          img{
            width: 50px;
            height: auto;
          }
          h2 {
            color: #5895db;
            font-size: 35px;
            font-weight: 500;
          }
        }
        &_bottom {
          h3 {
            color: #5c5d5d;
            font-size: 16px;
            font-weight: 500;
          }
        }
      }
    }
  }
  
  @media screen and (min-width: 280px) and (max-width: 767px) {
    .db {
      width: 100%;
      overflow-y: auto;
      &_sec {
        padding: 20px;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 30px;
      }
    }
  }

  
@media screen and (min-width: 768px) and (max-width: 1080px) {
  .db {
    width: 100%;
    overflow-y: auto;
    &_sec {
      padding: 20px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
    }
  }
}