.form {
  &_cont {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &_header {
      width: 100%;
      margin-bottom: 20px;
      display: flex;
      &_left {
        margin-left: 30px;
        width: 55%;
        display: flex;
        justify-content: space-between;
        h2 {
          font-size: 24px;
          font-weight: 700;
          text-align: center;
          color: #5c5d5d;
        }
      }
    }
  }
  &_sec {
    width: 95%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    &_inputs {
      &_slider {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &_label {
          font-size: 14px;
          color: #333;
        }
        .left {
          text-align: left;
          margin-right: 8px;
        }

        .right {
          text-align: right;
          margin-left: 8px;
        }
      }
      label {
        color: #5c5d5d;
        font-size: 14px;
        padding-bottom: 10px;
        font-weight: 600;
      }
      textarea {
        width: 100%;
        height: 100px;
        border: 1px solid #e6e6e6;
        border-radius: 5px;
        background-color: #ffffff;
        padding: 5px 10px;
        font-size: 14px;
        word-wrap: break-word;
        overflow-y: auto;
        color: #5c5d5d;
        margin-top: 5px;
        font-weight: 600;
      }
      textarea:focus-visible {
        outline: none !important;
      }
    }
  }
  &_button {
    width: 95%;
    margin: 30px 0;
    display: flex;
    justify-content: space-between;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .form {
    &_sec {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 15px;
      padding-left: 20px;
    }
    &_button {
      width: 88%;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1080px) {
  .form {
    &_sec {
      width: 100%;
      padding: 0 10px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 30px;
    }
    &_button {
      width: 97%;
    }
  }
}
