.llms {
  width: 100%;
  &_header {
    padding: 10px 20px;
    h2 {
      text-align: center;
      font-weight: 600;
      font-size: 20px;
    }
  }
  &_sec {
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    &_card {
      padding: 20px;
      border: 1px solid #c3c3c3;
      border-radius: 10px;
      cursor: pointer;
      &_top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        img {
          width: 40px;
          height: 40px;
        }
        h2 {
          color: #5895db;
          font-size: 35px;
          font-weight: 500;
        }
      }
      &_bottom {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        h3 {
          color: #5c5d5d;
          font-size: 20px;
          font-weight: 500;
        }
        span {
          color: #5895db;
          font-size: 12px;
          font-weight: 500;
          text-decoration: underline;
        }
      }
    }
  }
}

@media screen and (min-width: 280px) and (max-width: 767px) {
  .llms {
    width: 100%;
    height: calc(100vh - 160px);
    overflow-y: auto;
    &_sec {
      padding: 20px;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 30px;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1080px) {
  .llms {
    width: 100%;
    height: calc(100vh - 160px);
    overflow-y: auto;
    &_sec {
      padding: 20px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
    }
  }
}
