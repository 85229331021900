.aconfirm {
  position: fixed;
  background-color: #00000021;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  &_popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 450px;
    &_inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      h2 {
        font-size: 24px;
        color: #6b6a6a;
        font-weight: 600;
        margin-bottom: 20px;
        text-align: center;
      }
    }
    &_buttons {
      display: flex;
      justify-content: space-between;
      width: 100%;
      button {
        padding: 8px 20px;
        color: #fff;
        background: #104581;
        border-radius: 5px;
        border: none;
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;
      }
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .aconfirm {
    &_popup {
      padding: 20px;
      width: 300px;
    }
  }
}
