.table {
  &_container {
    &_left {
      display: flex;
      gap: 10px;
      .back {
        cursor: pointer;
      }
    }
    &_right {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      &_input {
        input {
          padding: 10px;
          border-radius: 6px;
          border: 1px solid gray;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .table {
    &_container {
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 10px;
      &_left {
        width: 100%;
        justify-content: flex-start;
      }
      &_right {
        width: 100%;
        flex-direction: column;

        &_input {
          width: 100%;
          justify-content: flex-start;
          input {
            padding: 10px;
            border-radius: 6px;
            border: 1px solid gray;
          }
        }
        &_button {
          width: 100%;
          display: flex;
          justify-content: flex-start;
        }
      }
    }
  }
}
