.table {
  &_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    h4 {
      font-size: 20px;
      font-weight: 600;
      color: #1a202c;
    }
    &_search {
      display: flex;
      gap: 20px;
      input {
        font-size: 14px;
        padding: 5px;
        border-radius: 6px;
      }
    }
  }
  &_sec {
    overflow-x: auto;
    &_cont {
      display: inline-block;
      min-width: 100%;
      vertical-align: middle;
      &_details {
        overflow: hidden;
        table {
          min-width: 100%;
          border-collapse: collapse;
          table-layout: fixed;
          border-top: 1px solid #e2e8f0;
          border-bottom: 1px solid #e2e8f0;
          background-color: #fff;
          thead {
            background-color: #edf2f7;
            tr {
              th {
                padding: 12px;
                text-align: left;
                font-weight: 700;
                color: #4a5568;
                border-bottom: 1px solid #e2e8f0;
                cursor: pointer;
              }
            }
          }
          tbody {
            background-color: #fff;
            border-bottom: 1px solid #e2e8f0;
            tr {
              td {
                padding: 12px;
                border-bottom: 1px solid #e2e8f0;
                color: #4a5568;
                text-align: left;
              }
            }
          }
        }
      }
    }
  }
  &_page {
    display: flex;
    justify-content: space-between;
    padding: 24px 0;
    align-items: center;
    &_section {
      display: flex;
      align-items: center;
      gap: 12px;
      select {
        padding: 8px;
        border: 1px solid #e2e8f0;
        border-radius: 4px;
        background-color: #fff;
        color: #4a5568;
      }
      span {
        font-size: 12px;
        font-weight: 500;
        color: #718096;
      }
    }
    ul {
      display: flex;
      align-items: center;
      gap: 12px;
      li {
        font-size: 12px;
        color: #2d3748;
        button {
          font-size: 12px;
          border-radius: 4px;
          padding: 4px 8px;
          cursor: pointer;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .table {
    &_container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;
      h4 {
        font-size: 20px;
        font-weight: 600;
        color: #1a202c;
      }
      &_search {
        input {
          font-size: 14px;
          padding: 5px;
          border-radius: 6px;
        }
      }
    }
    &_sec {
      overflow-x: auto;
      &_cont {
        display: inline-block;
        min-width: 100%;
        vertical-align: middle;
        &_details {
          overflow: hidden;
          table {
            min-width: 100%;
            border-collapse: collapse;
            table-layout: fixed;
            border-top: 1px solid #e2e8f0;
            border-bottom: 1px solid #e2e8f0;
            background-color: #fff;
            thead {
              background-color: #edf2f7;
              tr {
                th {
                  padding: 12px;
                  text-align: left;
                  font-weight: 700;
                  color: #4a5568;
                  border-bottom: 1px solid #e2e8f0;
                  cursor: pointer;
                }
              }
            }
            tbody {
              background-color: #fff;
              border-bottom: 1px solid #e2e8f0;
              tr {
                td {
                  padding: 12px;
                  border-bottom: 1px solid #e2e8f0;
                  color: #4a5568;
                  text-align: left;
                }
              }
            }
          }
        }
      }
    }
    &_page {
      flex-direction: column;
      gap: 10px;
      &_section {
        width: 100%;
        justify-content: flex-start;
      }
      ul {
        width: 100%;
        justify-content: flex-end;
      }
    }
  }
}
