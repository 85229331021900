.llm {
  width: 100%;
  &_header {
    display: flex;
    justify-content: flex-end;
    padding: 10px 20px;
  }
  &_sec {
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    &_card {
      padding: 20px;
      border: 1px solid #c3c3c3;
      border-radius: 10px;
      cursor: pointer;
      &_top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        img {
          width: 40px;
          height: auto;
        }
        .trash {
          width: 28px;
          height: 28px;
          cursor: pointer;
          border-radius: 50%;
          background-color: #f7f7f8;
          padding: 5px 7px;
        }
        h2 {
          color: #5895db;
          font-size: 35px;
          font-weight: 500;
        }
      }
      &_bottom {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        h3 {
          color: #5c5d5d;
          font-size: 20px;
          font-weight: 500;
        }
        span {
          color: #5895db;
          font-size: 12px;
          font-weight: 500;
          text-decoration: underline;
        }
      }
    }
  }
}
.status {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75vh;
  h1{
    font-size: 24px;
    color: #104581;
    font-weight: 600;
  }
}

.confirm2 {
  position: fixed;
  background-color: #00000021;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  &_popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 450px;
    &_inner {
      display: flex;
      flex-direction: column;
      h2 {
        font-size: 20px;
        color: #6b6a6a;
        font-weight: 600;
        margin-bottom: 40px;
        text-align: center;
      }
    }
    &_buttons {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      button {
        padding: 8px 20px;
        color: #fff;
        background: #104581;
        border-radius: 5px;
        border: none;
        font-size: 16px;
        font-weight: 500;
        margin: 0 20px;
        cursor: pointer;
      }
    }
  }
}

@media screen and (min-width: 280px) and (max-width: 767px) {
  .llm {
    width: 100%;
    height: calc(100vh - 160px);
    overflow-y: auto;
    &_sec {
      padding: 20px;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 30px;
    }
  }
  .confirm2 {
    &_popup {
      padding: 20px 10px;
      width: 300px;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1080px) {
  .llm {
    width: 100%;
    height: calc(100vh - 160px);
    overflow-y: auto;
    &_sec {
      padding: 20px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
    }
  }
}
