.llmd {
  &_header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    &_left {
      cursor: pointer;
    }
    &_right {
      display: flex;
      gap: 20px;
    }
  }
  &_cont {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
  }
  &_sec {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 15px 20px;
    border-radius: 10px;
    box-shadow: 0 0 1px 1px #d2d2d2;
    &_header {
      display: flex;
      justify-content: space-between;
      &_buttons {
        display: flex;
        gap: 10px;
      }
    }
    &_top {
      width: 100%;
      &_inputs {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        margin: 10px 0;
      }
      &_slider {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &_label {
          font-size: 14px;
          color: #333;
        }
        .left {
          text-align: left;
          margin-right: 8px; /* Optional space between label and slider */
        }

        .right {
          text-align: right;
          margin-left: 8px; /* Optional space between label and slider */
        }
      }
      label {
        color: #5c5d5d;
        font-size: 14px;
        padding-bottom: 10px;
        font-weight: 600;
      }
      textarea {
        width: 100%;
        height: 100px;
        border: 1px solid #e6e6e6;
        border-radius: 5px;
        background-color: #ffffff;
        padding: 5px 10px;
        font-size: 14px;
        word-wrap: break-word;
        overflow-y: auto;
        color: #5c5d5d;
        margin-top: 5px;
        font-weight: 600;
      }
      textarea:focus-visible {
        outline: none !important;
      }
      .textarea-container-disabled {
        background-color: lightgray;
        cursor: not-allowed;
      }

      .textarea-disabled {
        background-color: #e0e0e0;
        cursor: not-allowed;
      }
    }
  }
  &_bottom {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  &_upload {
    width: 50%;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0 0 1px 1px #d2d2d2;
    gap: 20px;
    &_left {
      width: 100%;
      display: flex;
      justify-content: center;
      padding-top: 15px;
      &_sec {
        width: 90%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        &_file {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #fff;
          border-radius: 10px;
          padding: 10px;
          margin-bottom: 20px;
          box-shadow: 0px 0px 4px 1px #dddddd;
          &_choose {
            width: 100%;
            height: 100px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            padding: 18px;
            border: 2px dashed #c3c3c3;
            img {
              width: 35px;
              height: 35px;
              cursor: pointer;
            }
            .text {
              display: flex;
              gap: 5px;
              align-items: center;
              padding-top: 10px;
              h3 {
                font-size: 14px;
                color: #5c5d5d;
                font-weight: 600;
              }
              span {
                font-size: 10px;
                color: #5c5d5d;
                font-weight: 600;
              }
            }
          }
        }
        &_button {
          display: flex;
          justify-content: space-between;
        }
      }
    }
    &_right {
      width: 100%;
      display: flex;
      justify-content: center;
      &_content {
        width: 95%;
        display: flex;
        flex-direction: column;
        padding: 5px;
        height: 150px;
        overflow-y: scroll;
        &_print {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border: 1px solid lightgray;
          border-radius: 6px;
          padding: 10px;
          margin-bottom: 14px;
          &_left {
            display: flex;
            align-items: center;
            width: 80%;
            h3 {
              font-size: 12px;
              color: #838383;
              text-align: left;
              overflow: auto;
            }
          }
          &_right {
            width: 10%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .trash {
              width: 28px;
              height: 28px;
              cursor: pointer;
              border-radius: 50%;
              background-color: #f7f7f8;
              padding: 5px 7px;
            }
          }
        }
      }
    }
  }
  &_scrape {
    width: 50%;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0 1px 1px #d2d2d2;
    &_top {
      p {
        font-size: 12px;
        color: #838383;
      }
      &_buttons {
        // margin-top: 20px;
        display: flex;
        justify-content: space-between;
      }
    }
    &_content {
      width: 100%;
      display: flex;
      gap: 5px;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      border-radius: 8px;
      background-color: #fff;
      overflow-y: auto;
      margin-top: 30px;
      &_print {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #c3c3c3;
        border-radius: 10px;
        padding: 10px 5px;
        gap: 10px;
        &_left {
          display: flex;
          align-items: center;
          width: 70%;
          h3 {
            font-size: 14px;
            font-weight: 500;
            color: #838383;
            text-align: left;
            overflow: auto;
            margin-left: 5px;
          }
        }
        &_right {
          width: 30%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 5px;
          h3 {
            font-size: 12px;
            color: #838383;
            overflow: auto;
          }
        }
      }
    }
  }
}
.user {
  position: fixed;
  background-color: #00000021;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  &_popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 450px;
    &_inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      h2 {
        font-size: 24px;
        color: #6b6a6a;
        font-weight: 600;
        text-align: center;
      }
    }
    &_body {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      gap: 20px;
      padding: 20px 0;
      &_inputs {
        width: 100%;
      }
    }
    &_buttons {
      display: flex;
      justify-content: space-between;
      width: 100%;
      button {
        padding: 8px 20px;
        color: #fff;
        background: #104581;
        border-radius: 5px;
        border: none;
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;
      }
    }
  }
}

.llmd_scrape_top_buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.llmd_scrape_top_buttons > button {
  margin-right: 10px; 
}

.llmd_scrape_top_buttons.single-button {
  justify-content: flex-end; 
}

.llmd_scrape_top_buttons.two-buttons {
  justify-content: space-between;
}

.llmd_upload_left_sec_button {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.llmd_upload_left_sec_button > button {
  margin-right: 10px;
}

.llmd_upload_left_sec_button.single-button {
  justify-content: flex-end;
}

.llmd_upload_left_sec_button.two-buttons {
  justify-content: space-between; 
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .llmd {
    &_header {
      align-items: center;
      &_right {
        gap: 10px;
      }
    }
    &_sec {
      &_top {
        &_inputs {
          grid-template-columns: repeat(1, 1fr);
        }
      }
    }
    &_bottom {
      width: 100%;
      flex-direction: column;
      gap: 20px;
    }
    &_upload {
      width: 100%;
      &_left {
        &_sec {
          width: 95%;
        }
      }
      &_right {
        &_content {
          &_print {
            &_right {
              width: 15%;
            }
          }
        }
      }
    }
    &_scrape {
      width: 100%;
      padding: 15px;
      &_content {
        &_print {
          &_left {
            display: flex;
            align-items: center;
            width: 68%;
            h3 {
              font-size: 12px;
              margin-left: 0;
            }
          }
          &_right {
            width: 32%;
            h3 {
              font-size: 10px;
            }
          }
        }
      }
    }
  }
  .user {
    &_popup {
      padding: 20px;
      width: 300px;
    }
  }
}
