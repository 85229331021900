.custom-button {
  border: none;
  padding: 10px 25px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.primary {
  background-color: #104581;
  color: #ffffff;
  border-radius: 5px;
  padding: 10px 20px;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
}

.secondary {
  background-color: #ffffff;
  color: #104581;
  border-radius: 10px;
  padding: 8px 20px;
  width: 100%;
  border: 2px solid #104581;
  font-size: 14px;
  font-weight: 500;
}

.small {
  background-color: #FFF;
  color: #104581;
  border-radius: 6px;
  padding: 4px;
  width: 100%;
  border: 2px solid #104581;
  font-size: 12px;
  font-weight: 400;
}

