.table_sec_cont_details {
    position: relative;
  }
  
  .loading-container {
    position: relative;
    height: 200px; 
  }
  
  .loading-indicator {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 1.5rem;
    color: #333;
  }