.news {
    padding: 0 10px;
    &_header {
        margin-bottom: 10px;
      h4 {
        font-size: 20px;
        font-weight: 600;
        color: #1a202c;
      }
    }
    &_content {
      padding: 20px;
      box-shadow: 0 0 1px 1px #c1c1c1;
      border-radius: 12px;
      &_inputs {
        display: flex;
        flex-direction: column;
        label {
          color: #5c5d5d;
          font-size: 16px;
          padding-bottom: 10px;
          font-weight: 600;
        }
        input,
        textarea {
          border: none;
          width: 100%;
          border-radius: 5px;
          background-color: #ffffff;
          padding: 12px;
          color: #5c5d5d;
          font-size: 14px;         
          font-weight: 400;
          border: 1px solid #e6e6e6;
          margin-bottom: 20px;
          resize: none;            
          &::placeholder {
            font-size: 14px;       
            color: #838383;        
            font-weight: 400;
          }
          &:focus {
            outline: none;
          }
        }
      }
      &_submit {
        margin-top: 20px;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
  