.confirm {
  position: fixed;
  background-color: #00000021;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;

  &__dialog {
    background-color: white;
    box-shadow: 0 4px 16px 0 #ffffff40;
    padding: 2vw;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 20px;
    color: rgb(0, 0, 0);
    &__button {
      min-width: 8vw;
      padding: 1vw;
      border: none;
      &--cancel {
        background: rgb(158, 158, 158);
        padding: 8px;
        margin-top: 20px;
      }
    }

    &__button:focus {
      outline: none;
    }

    &__content {
      padding: 0 0;
    }

    &__description {
      font-size: 1.4vw;
      color: #5c5b5b;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      margin: 0;
      line-height: 1.57;
      letter-spacing: normal;
      text-align: center;
    }

    &__footer {
      justify-content: space-evenly;
      display: flex;
    }
  }
}

@media screen and (min-width: 280px) and (max-width: 760px) {
  .confirm {
    &__dialog {
      width: 85%;
      padding: 6vw 3vw;
      &__content {
        p {
          font-size: 5vw;
        }
      }
      &__button {
        width: 30vw !important;
        padding: 2vw 0 !important;
        font-size: 4vw !important;
      }
    }
  }
}
