.ul {
    &_cont {
      width: 100%;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: url(../../../Assets/Images/LoginBG2.jpg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      &_section {
        width: 35%;
        border-radius: 10px;
        box-shadow: 0 0 4px 2px rgb(161, 161, 161);
        padding: 20px;
        background-color: #fff;
        &_header {
          h3 {
            font-size: 28px;
            font-weight: 500;
            color: #25242e;
            text-align: center;
            margin-bottom: 10px;
          }
        }
        &_inputs {
          width: 100%;
          display: grid;
          gap: 20px;
          &_data {
            width: 100%;
          }
        }
        &_forgot {
          margin-top: 10px;
          display: flex;
          justify-content: space-between;
          p {
            font-size: 12px;
            font-weight: 500;
            text-align: end;
            cursor: pointer;
          }
        }
        &_buttons{
          margin-top: 10px;
          padding: 10px 0;
        }
        &_bottom {
          margin-top: 10px;
          p {
            text-align: center;
            color: #5c5b5b;
            font-size: 12px;
            font-weight: 500;
            span {
              color: #092b46;
              cursor: pointer;
              font-weight: 600;
            }
          }
        }
      }
    }
  
    .loader-container {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.5);
      z-index: 9;
    }
  }
  
  @media screen and (min-width: 320px) and (max-width: 768px) {
    .ul {
      width: 100%;
      &_cont {
        &_section {
          width: 90%;
          &_logo {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 40px 0 25px 0;
            &_img {
              width: 118px;
              height: max-content;
            }
          }
          &_header {
            h3 {
              font-size: 30px;
              font-weight: 500;
              margin-bottom: 12px;
            }
            p {
              font-size: 16px;
              font-weight: 400;
              color: #838383;
              margin-bottom: 20px;
            }
          }
          &_inputs {
            width: 100%;
            display: grid;
            gap: 22px;
            &_data {
              width: 100%;
            }
          }
          &_forgotpws {
            display: flex;
            justify-content: flex-end;
            margin-top: 8px;
            h3 {
              font-size: 16px;
              font-weight: 400;
              color: #104581;
              cursor: pointer;
            }
          }
          &_buttons {
            padding-top: 27px;
          }
        }
      }
    }
  }
  