@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap");

.App {
  font-family: "Jost", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  margin: 0;
  padding: 0;
}

ul {
  margin: 0;
  padding: 0;
  li {
    list-style-type: none;
  }
}


.error {
  font-size: 10px;
  color: red;
}

::-webkit-scrollbar {
  width: 2px !important;
  height: 2px !important;
}
::-webkit-scrollbar-thumb {
  background: #104581;
  border-radius: 0.3vw;
}
::-webkit-scrollbar-thumb:hover {
  background: #c3c3c3;
}
::-webkit-scrollbar-track {
  border-radius: 0.5vw;
  background: #eeeeee;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

label, input, textarea{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}